<template>
    <div id="gjs"></div>
</template>

<script>
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import { mapActions } from "vuex";

export default {
   data: () => ({
      editor: null,
      template: null
  }),
  methods: {
    ...mapActions("templates", {
      getTemplate: "findById"
    }),
    ...mapActions("notifications", ["error"])
  },
  async beforeMount() {
    const id = this.$route.params.id;

    if (id) {
      try {
        let template = await this.getTemplate(id);
        this.template = template;
        this.editor.setComponents(this.template.html);
        this.editor.setStyle(this.template.css);
        this.$store.dispatch("setTitle", this.$t("views.templates.template.viewParam", { name: template.name }));
        this.editor.DomComponents.getWrapper().onAll(comp => {
          comp.set({
            editable: false,
            draggable: false,
            hoverable: false,
            highlightable: false,
            selectable: false
          });
        });
      } catch (error) {
        this.error(error.message)
      }
    }
  },
  mounted() {
    var that = this;
    this.editor = grapesjs.init({
      height: "94.3%",
      container: "#gjs",
      noticeOnUnload: false,
      styleManager: { clearProperties: 0 },
      storageManager: {
        type: null
      }
    });

    const head = this.editor.Canvas.getDocument().head;
    head.insertAdjacentHTML('beforeend', `<link href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">`)

    this.editor.Commands.extend("preview", {
      run(editor, sender) {
        editor.stopCommand("sw-visibility");
        editor.getModel().stopDefault();
        
        const panels = editor.Panels.getPanelsEl();
        const canvas = editor.Canvas.getElement();
        const pfx = editor.Config.stylePrefix;

        panels.style.display = "none";
        const canvasS = canvas.style;
        canvasS.width = "100%";
        canvasS.height = "100%";
        canvasS.top = "0";
        canvasS.left = "0";
        canvasS.padding = "0";
        canvasS.margin = "0";
        editor.refresh();
      }
    });

    const pn = this.editor.Panels;   
    pn.getButton('options', 'preview').set('active', 1);
  },
  destroyed() {
    this.editor = null;
  }
};
</script>

<style lang="scss">
$bgcolor: #004b87;
/* Primary color for the background */
.gjs-one-bg {
  background-color: $bgcolor;
}
</style>
